import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/Personal-Website/Personal-Website/src/components/layout/MD_Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Deploying Your Gatsby Website with Github Actions`}</h1>
    <p><a parentName="p" {...{
        "href": "https://docs.github.com/en/actions"
      }}>{`Github actions`}</a>{` are a great way to automate all of your code on Github.
Anything that you plan on doing more than once can be automated and leaving
more time for the fun things.`}</p>
    <p>{`Using the code walked through below you can reliably deploy your `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/"
      }}>{`Gatsby
Website`}</a>{` on Github pages as easily as you push to
a remote repository.`}</p>
    <h2>{`Create a New Github Action`}</h2>
    <p>{`Adding a workflow to your current repository can be done creating a new yaml
file and adding it to `}<inlineCode parentName="p">{`.github/workflows/<workflow-name\\>.yml`}</inlineCode>{`. When
this file is pushed to your remote Github Repo you will be able to find a
new action on your repo action page.`}</p>
    <h2>{`Build the workflow file`}</h2>
    <p>{`You can find details of the breakdown below, or you can bottom of the page
to view the full action file.`}</p>
    <h3>{`Add a Workflow name`}</h3>
    <pre><code parentName="pre" {...{}}>{`name: Gatsby Publish
`}</code></pre>
    <h3>{`Add the Workflow Trigger`}</h3>
    <p>{`The trigger is the event that causes our Action to run. In this case I
have set it so that a push to my master branch will result in a new
website build. You can learn more about this in the `}<a parentName="p" {...{
        "href": "https://docs.github.com/en/actions/using-workflows/workflow-syntax-for-github-actions#on"
      }}>{`workflow syntax docs`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`on:
  push:
    branches:
      - master
`}</code></pre>
    <h3>{`Create Job`}</h3>
    <p>{`This is the where we define what we want to run to the GitHub runner.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Set up job`}</p>
        <p parentName="li">{`The first task is to set up the machine for the GHA(GitHub Action) to run
on. You can view all machine options in the `}<a parentName="p" {...{
            "href": "https://docs.github.com/en/actions/using-workflows/workflow-syntax-for-github-actions#jobsjob_idruns-on"
          }}>{`documentation`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`  jobs:
    build:
      runs-on: ubuntu-latest
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Checkout the Repository`}</p>
        <p parentName="li">{`This step checks out the repository the action is in so you can access
its code.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`  - uses: actions/checkout@v2
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Initialize Git User`}</p>
        <p parentName="li">{`This is necessary so that we can push to the deployment branch. The
checkout action handles branch permissions, so we don't have to worry about
authentication.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`  - name: Initialize GH User
    run: |
      git config user.name "GitHub Actions"
      git config user.email "actions@github.com"
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Set Up and Install Node Modules`}</p>
        <p parentName="li">{`Using the package.json file in the websites root we will set up npm and
install the needed modules to build our website.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`  - name: Set Node.js
    uses: actions/setup-node@master
    with:
      node-version: '16'

  - name: Install dependencies
    run: npm install
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Build the Website`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`  - name: Build
    run: npm run build
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Deploy the Website`}</p>
        <p parentName="li">{`First I copy over my CNAME file so that the website is
served from my custom domain. Next, and this is where it gets especially
confusing, I create a branch with the built website. To do this I commit
the built website to the current branch and then use subtree to split the
'public' subdirectory into its own branch called 'gh-pages'. As gh-pages
is the branch I serve my website from I then push this branch to origin
where is published to the world`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`  - name: Deploy
    run: |
      # Add in the correct CNAME - Optional
      echo "<your-domain>" > public/CNAME

      # Commit the build then use 'git subtree' to create a branch with just the public contents
      git add public -f
      git commit -m "Build Production Website"
      git checkout -b gh-pages \`git subtree split --prefix public master\`

      # Push to Production
      git push --set-upstream origin gh-pages --force
`}</code></pre>
      </li>
    </ol>
    <h2>{`Using the Action`}</h2>
    <p>{`Using this action now can spend less time deploying and more time developing!
You can find the past runs for this action for this website in my
`}<a parentName="p" {...{
        "href": "https://github.com/CannonLock/Personal-Website/actions"
      }}>{`repository`}</a>{`, and
you can find the actual file `}<a parentName="p" {...{
        "href": "https://github.com/CannonLock/Personal-Website/blob/master/.github/workflows/deploy-website.yml"
      }}>{`there as well`}</a>{`.`}</p>
    <h2>{`Gatsby Deployment with GitHub Actions Full Code`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`name: Gatsby Publish

on:
    push:
        branches:
            - master

jobs:
    build:
        runs-on: ubuntu-latest
        steps:
            -   uses: actions/checkout@v2

            -   name: Initialize GH User
                run: |
                    git config user.name "GitHub Actions"
                    git config user.email "actions@github.com"

            -   name: Set Node.js
                uses: actions/setup-node@master
                with:
                    node-version: '16'

            -   name: Install dependencies
                run: npm install

            -   name: Build
                run: npm run build

            -   name: Deploy
                run: |
                    # Add in the correct CNAME - Optional
                    echo <your-domain> > public/CNAME

                    # Commit the build then use 'git subtree' to create a branch with just the public contents
                    git add public -f
                    git commit -m "Build Production Website"
                    git checkout -b gh-pages \`git subtree split --prefix public master\`

                    # Push to Production
                    git push --set-upstream origin gh-pages --force
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      